.add-item {
  position: absolute;
  right: 16px;
}

.cancel {
  background: white;
  color: $success;
}

.extra-margin {
  margin: 0 0 10px 0;
}

.button-loading {
  margin-left: 5px;
}

.btn {
  border-radius: 3px !important;
  font-size: 13px;
  line-height: 12px;
  font-weight: 600;

  .spinner-border-sm {
    margin-left: 5px;
  }

}

.btn-success:hover {
  background-color: #006E09;
}

.btn-success:not(:disabled):not(.disabled):active {
  background-color: #005807;
}

.btn > svg{
  height: 15px;
  margin-right: 10px;
}
