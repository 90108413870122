.nav-tabs .nav-link {
  font-weight: 600;
  border: none;
  background-color: $white;
  color: #6E717C;
  border-bottom: 3px solid #D8DBDE;
}

.nav-tabs .nav-link:hover {
  border-bottom: 3px solid #236192;
  color: $ptc-convergence-select-color;
}

.nav-tabs {
  border:none;
  margin-bottom: 20px;
}

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
  color: $ptc-convergence-select-color;
  border-bottom: 3px solid $ptc-info;
  background-color: transparent;
}
