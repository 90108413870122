.card {
  .card-body {
    .detail-props {
      font-weight: bold;
      text-transform: capitalize;
    }
  }
  .card-footer > * {
    margin: 0.25rem;
  }
}


.linkable-card {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  text-decoration: none;

  &::after {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    opacity: 0;
    -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  }

  &:hover {
    -webkit-transform: scale(1.01, 1.01);
    transform: scale(1.01, 1.01);

    &::after {
      opacity: 1;
    }
  }
}

.customer-card {
  position: relative;
  display: block;
  margin: 0 0 3px 0;
  border: none;
  border-radius: 0;
  background-color: $white;

  .row {
    margin-bottom: 10px;
  }
}

.customer-card-body {
  padding: 0 20px 0 20px;
}

.customer-card-content {
  padding: 20px 0 20px 0;
}

.customer-card-chevron {
  border-left: 2px solid $light;
}

.card-link {
  text-decoration: none;
}

.card-img-chevron {
  position: absolute;
  top: 50%;
  left: 97%;
  transform: translate(-50%,-50%);
}

html:not([dir="rtl"]) .card-link + .card-link {
  margin-left: 0;
}

.details-card {
  .card-header {
    border: none;
  }
}

.environment-control-card-content{
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.environment-card-padding{
  padding-top: 15px;
  padding-bottom: 15px;
}
