.modal-footer, .modal-header {
  border: none;
}

.modal-header {
  font-size: 1.5rem;
  line-height: 1.25rem;
  color: #232B2B;
  font-weight: 400;
}

.close:focus-visible,.close:focus{
  outline: none !important;
}