.banner-form-group {
  margin-left: 20px;
}

input {
  margin: 4px;
}

input:hover, textarea:hover, .form-control:hover {
  border: 1px solid #236192;
  background: #FFFFFF;
}

input:focus, textarea:focus, .form-control:focus {
  border: 2px solid #0094C8;
  background: #FFFFFF;
  box-shadow: none;
}

.form-control:disabled {
  background-color: $ptc-convergence-input-disabled-bg;
  border-color: $ptc-convergence-input-disabled-border;
  color: $ptc-convergence-input-disabled-color;
}

textarea {
  border-color: $ptc-convergence-input-gray;
  color: $ptc-convergence-input-light-gray;
  resize: none;
  overflow: hidden;
}

.invalid-feedback {
  display: block;
  width: 100%;
  margin: 6px;
  font-size: 80%;
  color: #e55353;
}

select {
  border-color: $ptc-convergence-input-gray;
  color: $ptc-convergence-select-color;
  margin: 6px;
}

select:hover {
  border-color: $ptc-convergence-select-border-color;
  color: $ptc-convergence-select-color;
}

label {
  font-size: 14px;
  color: $ptc-convergence-select-color;
  margin: 4px;
}

.environment-radio-label{
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #20262A;
  margin-bottom: 0;
  margin-left: 10px;
}

.environment-control-card-content input[type="radio"], input[type="checkbox"] {
  // width: auto;
  width: 14px;
  height: 14px;
  accent-color: #006E09;
}
