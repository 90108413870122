.news-card {
  background-color: $white;
  border-radius: 3px;
  border: none;

  .img-thumbnail {
    border: none;
    border-radius: 0;
  }

  .new-heading {
    color: $success;
  }

  .news-card-footer {
    color: #C8C9C7;
  }

  .news-card-date {
    font-size: 16px;
    font-weight: 400;
  }

  .card-title {
    font-weight: 700;
    font-size: 18px;
  }
}
