.c-sidebar {
  font-weight: 600;
}

.c-sidebar.c-sidebar-light {
  border-right: none;
  font-weight: bold;
  :focus {
    outline: none;
  }
}

.c-sidebar.c-sidebar-light .c-sidebar-nav-dropdown.c-show {
  background: $white;
}

.c-sidebar-dark{
  background: #3B4550;
  color: #FFFFFF;
}

.c-sidebar .c-sidebar-nav-link.c-active{
  background: #0094C8;
}

.c-sidebar .c-sidebar-nav-link:hover{
  background: #20262A;
}

.c-sidebar .c-sidebar-nav-link {
  color: $white;
}
