// Variable overrides


//
// PTC default colors
//

$white:               #fff !default;
$black:               #000015 !default;
$gray-base:           #3c4b64 !default;
$primary-base:        #20262A !default;
$primary-dark:        #1f1498 !default;
$primary:             #3D4647 !default;
$sidebar-backdrop-bg: $white !default;
$sidebar-dark-bg:     $white !default;
$secondary-base:      #4E5D66 !default;
$secondary-dark:      #3E4A52 !default;
$secondary:           #617480 !default;
$danger-dark:         #A7232E !default;
$danger:              #D33E3E !default;
$critical-dark:         #d93737 !default;
$critical:              #F1063E !default;
$info-dark:           #2982cc !default;
$info:                #39f !default;
$success-dark:        #006E09 !default;
$success:             #00890B !default;
$warning-dark:        #f6960b !default;
$warning:             #F1B434 !default;
$light-gray:          #F9F9F9 !default;
$ptc-info:            #0094C8 !default;

$ptc-convergence-input-gray:            #C2C7CE !default;
$ptc-convergence-input-light-gray:      #6E717D !default;
$ptc-convergence-input-blue:            #005878 !default;

$ptc-convergence-input-disabled-border: #ADB5BD;
$ptc-convergence-input-disabled-bg: #F7F7F7;
$ptc-convergence-input-disabled-color: #AEB5BE;

$ptc-convergence-select-color: #232B2D;


$ptc-convergence-select-border-color: #005878;
$ptc-convergence-select-disabled-color: #AEB5BE;

$theme-colors: () !default;
$theme-colors: map-merge(
    (
      "primary":    $primary,
      "secondary":  $secondary,
      "success":    $success,
      "info":       $info,
      "warning":    $warning,
      "danger":     $danger,
      "critical":   $critical,
      "ptc-info":   $ptc-info,
    ),
    $theme-colors
);

$body-bg:                                  $light-gray !default;
$font-size-base :                          1rem !default;
$sidebar-light-nav-link-hover-color:       $white !important;
$sidebar-light-nav-link-hover-bg:          #0094C8 !default;
$sidebar-light-nav-link-hover-icon-color:  $white !default;
$border-radius :                           2px !default;
$sidebar-light-nav-dropdown-indicator-hover: $primary !default;

$sidebar-light-nav-link-active-bg:         #0094C8 !default;
$sidebar-light-nav-link-active-icon-color: #0094C8 !default;
$sidebar-light-nav-link-active-color:      #0094C8  !default;

$input-border-color: $ptc-convergence-input-gray;
$input-color: $ptc-convergence-input-light-gray;
$input-border-radius: 0;

$modal-content-border-radius: 0;

$breadcrumb-active-color: #ADB5BD !default;
$breadcrumb-font-size: 14px;

$table-color: $ptc-convergence-select-color !default;
